'use client';

import { ErrorComponent } from 'components/Error';
import { ErrorPage } from 'components/ErrorPage';

export default function Error({ error }: { error: Error & { digest?: string }; reset: () => void }) {
    const reset = () => {
        window.location.reload();
    };

    return (
        <ErrorPage title="" backButton={{ href: '/dashboard', text: 'Return to dashboard' }}>
            <ErrorComponent error={error} reset={reset} />
        </ErrorPage>
    );
}
